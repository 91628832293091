.title {
  font-family: "Poppins", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  flex: 1 1;
}
.Main-header {
  display: flex;
  flex-direction: row;
  grid-template-columns: 10rem 5rem auto;
  align-items: center;
}
.List {
  padding-right: 1rem;
}
.button {
  padding-left: 1px;
}
.Noti {
  margin-right: 1rem;
}
.Profile {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}
.Profile-Name {
  margin-right: 1rem;
  font-size: 18px;
  color: #e10c00;
  font-weight: 600;
}
.Arrow-Menu svg path {
  size: 15px;
  fill: #e10c00;
}
.Arrow-Menu {
  cursor: pointer;
}
