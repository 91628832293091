.Main-Button-Order {
  display: flex;
  background-color: white;
  justify-content: center;
  padding: 0.56rem 1rem 0.56rem 1rem;
  word-spacing: 100%;
  border-radius: 0.5rem;
  border: none;
  align-items: center;
  margin-top: 2rem;
}
.title-button {
  padding: 0;
  top: 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: #444444;
  font-family: var(--Ffamily);
}
.ordericon-2 {
  padding: 0.15rem 0.28rem 0 0.28rem;
  border-radius: 1rem;
  background: none;
  margin-right: 1rem;
  cursor: pointer;
}
