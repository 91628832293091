.Counterbutton , .CounterSavebutton {
    display: flex;
    background: var(--button);
    align-items: center;
    gap: .5rem;
    border: none;
    border-radius: .85rem;
    height: 2.8rem;
    padding: 0 1rem 0 1rem;
    cursor: pointer;
}
.CounterSavebutton {
    margin-bottom: 2rem;
}
.Countertext {
    font-family: var(--Ffamily);
    font-size: 15px;
    color: white;
    font-weight: 600;
}

.Counteroptions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2rem;
}
.Counter-box {
    display: flex;
    flex-direction: column;
    height: 8rem;
    width: 8rem;
    background: #FFEBD2;
    border: 5px solid #F6951F;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: .5rem;
}
.Counternumbering {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: white;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    gap: 0;
    background-color: #F6951F;
    height: 2rem;
    width: 2rem;
    margin-bottom: 0;
    border-radius: 1rem;
}
.Countersection {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    
}
