.AllOptions{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.options , .options2 , .options3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: .5rem;
}
.optiontlt {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}