:root {
  --mainback: #faf9fb;
  --button: linear-gradient(89.87deg, #ff8c00 0.11%, #de0000 99.89%);
  --Ffamily: "Poppins", sans-serif;
}
.App {
  font-family: var(--Ffamily);
  background: var(--mainback);
  height: 100vh;
  width: 100vw;
  display: flex;
}
.AppBack {
  background-color: var(--mainback);
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 25px;
  font-weight: 700;
}
.width {
  width: 100% !important;
}
.Sider {
  width: 15%;
}
.Container {
  display: flex;
  flex-direction: column;
  width: 85%;
  overflow-y: scroll;
  margin: 2rem 0 2rem 2rem;
  padding-right: 2rem;
}

/* Mobile Responsive */

@media (max-width: 480px) {
  .Sider {
    width: 8%;
    display: none;
  }
}

/* Tablet Responsive  */

@media (min-width: 768px) and (max-width: 1180px) {
  .Sider {
    width: 8%;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  .Container {
    display: flex;
    flex-direction: column;
    width: 92%;
    overflow-y: scroll;
    margin: 2rem 0 2rem 2rem;
    padding-right: 2rem;
  }
}
