.itemslist {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}
.top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.tabi {
    background-color: white;
    height: auto;
    max-height: 81vh;
    min-height: 41vh;
    margin-top: 1rem;
    border-radius: 0.5rem;
    overflow-y: auto;
}
