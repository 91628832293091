.Main-Button {
    display: flex;
    background-color: white;
    justify-content: center;
    padding: .56rem 1rem .56rem 1rem;
    word-spacing: 100%;  
    border-radius: .5rem; 
    border: none;
    margin-right: 1rem;
    align-items: center;
}
.title-button {
    padding: 0;
    top: 0;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    color: #444444;
    font-family: var(--Ffamily );

}
.ordericon {
    padding: 0.15rem .28rem 0 .28rem;
    background: #FFF4F4;
    border-radius: 1rem;
    margin-right: 1rem;
    cursor: pointer;
}