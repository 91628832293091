.Sidebar {
  background: White;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 2rem;
  transition: all 300ms ease;
  height: 100vh;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
}
.menu {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.menuitems {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  height: 2.5rem;
  gap: 0.5rem;
  margin-left: 2rem;
  position: relative;
  transition: all 300ms ease;
  font-size: 14px;
  border-radius: 0.8rem;
  color: #a3a3a3;
  padding: 0.3rem 2rem 0.3rem 1rem;
  text-decoration: none;
}
.menuitems:hover {
  cursor: pointer;
}
.active {
  background: var(--button);
  margin-left: 10%;
  margin-right: 8%;
  color: white;
  display: flex;
  text-decoration: none;
}
.active::before {
  content: "";
  margin-left: calc(1rem - 18px);
}
.icon {
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem;
}
.icon:hover {
  cursor: pointer;
}
.icon svg path {
  stroke: #a3a3a3;
}
.activated svg path {
  stroke: #fff;
}
/* .logo2 {
    display: none;
} */
.Arrowicon {
  display: flex;
  cursor: pointer;
  position: absolute;
  border-radius: 10px;
  right: -19px;
}
/* .menuitems span {
    display: none;
} */

@media (min-width: 768px) and (max-width: 1180px) {
  .menutlt,
  .logo2 {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  .Sidebar {
    background: White;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 2rem;
    transition: all 300ms ease;
    height: 100vh;
    align-items: center;
    align-content: center;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  .menu {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    align-content: center;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  .menuitems {
    margin-left: 0;
    padding: 0.3rem;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  .active {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  .icon {
    margin-top: 0.2rem;
    padding: 0 0.5rem 0 0.5rem;
  }
}
