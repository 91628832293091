.menus {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.menuitemss{
    display: flex;
    gap: 1.5rem;
    align-items: center;
    height: 2.5rem;
    gap: 0.5rem;
    margin-left: 2rem;
    position: relative;
    transition: all 300ms ease;
    font-size: 14px;
    border-radius: 0.8rem;
    color:  #A3A3A3;
    padding: 0.3rem 2rem 0.3rem 1rem ;
    text-decoration: none;
    border: none;
    background: none;

}
.menuitemss:hover {
    cursor: pointer;

}
.actives {
    background: var(--button);
    margin-left: 10%;
    margin-right: 8%;
    color: white;
    display: flex;
    text-decoration: none;
}
.actives::before{
    content: '';
    margin-left: calc( 1rem - 18px );

}
.iconss {
    justify-content: center;
    align-items: center;
    margin-top: 0.4rem;
}
.iconss:hover {
    cursor: pointer;
}
.iconss svg path {
    fill:#A3A3A3;
}
.activateds svg path {
    fill: white;
}
.iconsf svg path {
    stroke: #A3A3A3;
}
.activatedf svg path {
    stroke: white;
}
/* .logo2 {
    display: none;
} */