.Maintab {
  background-color: white;
  height: auto;
  max-height: 75vh;
  min-height: 35vh;
  margin-top: 2rem;
  border-radius: 0.5rem;
  overflow-y: auto;
}

.MainTitle {
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  padding-left: 2rem;
}
.Sliders {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.Savesbutton {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.btn9 {
  display: flex;
  flex-direction: row-reverse;
}
.inp {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
}
.inps {
  visibility: none;
}
.Stitle {
  font-family: var(--Ffamily);
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.Slide {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}
.sld {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.radiobtn {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
input[type="radio" i] {
  accent-color: red;
}
p {
  margin: 0;
}
.slideimage {
  height: 10rem;
  width: 10rem;
}
.main {
  display: flex;
  width: 10rem;
  height: 10rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
  /* border: 1.5px dashed #000000; */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
.addmore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: black;
}
.Sdelete {
  position: relative;
  cursor: pointer;
  margin-left: 8.3rem;
  top: -2.7rem;
  margin-bottom: -3rem;
}
