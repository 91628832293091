.Maincard {
  overflow-y: auto;
}
.CafeDisply {
  display: grid;
  grid-template-columns: 20rem auto;
  background-color: white;
  align-items: start;
  padding-top: 2rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
  gap: 1rem;
}
.menubar {
  margin-right: 2.5rem;
  margin-top: 1.5rem;
}
.Menucardtitle {
  font-family: var(--Ffamily);
  font-size: 14px;
  font-weight: 500;
  color: #656565;
  /* align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #D3D3D3; */
}
.Menucard {
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
}
.section {
  display: none;
  transition: 1s ease-in-out;
  gap: 1rem;
}
.activess {
  display: block;
  transition: 1s ease-in-out;
}
.sec {
  display: flex;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #d3d3d3;
  gap: 1rem;
  margin-bottom: 2rem;
}
