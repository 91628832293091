.tablemanage {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-content: center;
    padding-right: 2rem;
}
.Tableoptionss {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 2rem;
}
.tablesections {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    justify-content: flex-start;
    
}
.tablebtn {
    font-family: var(--Ffamily);
    font-weight: 600;
    background: #00E384;
    color: white;
    border: none;
    border-radius: .5rem;
    padding: .7rem;
    cursor: pointer;
}
