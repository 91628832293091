.tablebutton , .Savebutton {
    display: flex;
    background: var(--button);
    align-items: center;
    gap: .5rem;
    border: none;
    border-radius: .85rem;
    height: 2.8rem;
    padding: 0 1rem 0 1rem;
    cursor: pointer;
}
.Savebutton {
    margin-bottom: 2rem;
}
.tabletext {
    font-family: var(--Ffamily);
    font-size: 15px;
    color: white;
    font-weight: 600;
}

.Tableoptions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2rem;
}
.table-box {
    display: flex;
    height: 8rem;
    width: 8rem;
    background: #FFEBD2;
    border: 5px solid #F6951F;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: .5rem;
}
.numbering {
    position: absolute;
    margin: 0;
    padding: 0;
    color: rgb(255, 255, 255);
    justify-content: center;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.2rem;
    gap: 0;
}
.tablesection {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    
}
