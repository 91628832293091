.DashDisplays {
  overflow-y: auto;
}
.MainDisplay {
  display: flex;
  align-items: center;
  padding-top: 2rem;
  gap: 1rem;
}
.Toggle {
  display: flex;
  gap: 1rem;
  background-color: white;
  border-radius: 0.5rem;
}
.Daily,
.Weekly,
.Monthly {
  color: black;
  background: rgba(245, 16, 16, 0);
  border: none;
  padding: 0.56rem 1rem 0.56rem 1rem;
  border-radius: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
.Active {
  background: var(--button);
  color: white;
  transition: 0.2s linear;
}
.Cards {
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}
.card1,
.card2,
.card3,
.card4 {
  background-color: white;
  border-radius: 0.5rem;
  flex: 1;
  padding: 0.5rem;
}
.top {
  display: flex;
  align-items: center;
  /* gap: 6rem; */
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  margin-bottom: -1rem;
  margin-top: 0.5rem;
}
h1 {
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--Ffamily);
  flex: 1;
}
h2 {
  font-size: 2.5rem;
  flex: 1;
}
.bottom {
  display: flex;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  margin-bottom: -1.5rem;
  margin-top: -1rem;
}
.icons,
.chart {
  margin-right: 0.5rem;
}
/* .icons svg path {
    fill: white;
} */
.Orders {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding-top: 1rem;
  flex-direction: row;
  margin: 0;
}

h4 {
  font-size: 30px;
  font-family: var(--Ffamily);
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.Toggle-Order {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 1rem;
  background-color: white;
  border-radius: 0.5rem;
}
.All,
.Take,
.Pick,
.Dine {
  color: black;
  background: rgba(245, 16, 16, 0);
  border: none;
  padding: 0.56rem 2rem 0.56rem 2rem;
  border-radius: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
.Activn {
  background: var(--button);
  color: white;
  padding: 0 3rem 0 3rem;
  transition: 0.2s linear;
}

/* CTable Css */

.tab {
  background-color: white;
  height: auto;
  max-height: 81vh;
  min-height: 41vh;
  margin-top: 1rem;
  border-radius: 0.5rem;
  overflow-y: auto;
}
.tab1 {
  background-color: white;
  height: 53.2vh;
  margin-top: 1rem;
  border-radius: 0.5rem;
  overflow-y: auto;
}
.App1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.Table {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  text-align: center;
  justify-content: center;
  height: 4rem;
}
.Table-top {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  text-align: center;
  justify-content: center;
  height: 4rem;
  position: -webkit-sticky;
  background-color: white;
  position: sticky;
  top: 0;
}
.Table:hover {
  background-color: #fbfbfb;
}
.Table-head {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  z-index: 0;
}
.Table-head img {
  height: 50px;
  width: 50px;
}
.Table-head1 {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
  z-index: 0;
}
.title-u {
  font-size: 14px;
  color: #707070;
}

.status {
  box-sizing: border-box;
  width: 74px;
  height: 23px;
  background: #defff1;
  border: 1px solid #00e384;
  border-radius: 11px;
  color: #00e384;
  font-weight: 400;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #f1f1f1; */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e7e4e4;
  border-radius: 10px;
}

.apexcharts-toolbar {
  display: none !important;
}
/* .charts1 {
    position: absolute;
    height: 20px;
  } */

/* Cards Progress bar Data  */

.cardin1,
.cardin2,
.cardin3,
.cardin4 {
  width: 4rem;
}
.cardin1 .CircularProgressbar {
  display: flex;
  overflow: visible;
}

/* CircularProgressbar-path CSS */

.cardin1 .CircularProgressbar-path {
  stroke: #ff8c00 !important;
}
.cardin2 .CircularProgressbar-path {
  stroke: #00e384 !important;
}
.cardin3 .CircularProgressbar-path {
  stroke: #ff0000 !important;
}
.cardin4 .CircularProgressbar-path {
  stroke: #28a900 !important;
}

/* CircularProgressbar-text CSS  */

.cardin1 .CircularProgressbar-text {
  fill: #ff8c00 !important;
  font-weight: 600 !important;
}
.cardin2 .CircularProgressbar-text {
  fill: #00e384 !important;
  font-weight: 600 !important;
}
.cardin3 .CircularProgressbar-text {
  fill: #ff0000 !important;
  font-weight: 600 !important;
}
.cardin4 .CircularProgressbar-text {
  fill: #28a900 !important;
  font-weight: 600 !important;
}

/* CircularProgressbar-trail CSS */

.cardin1 .CircularProgressbar-trail {
  stroke: #ffe9cf !important;
}
.cardin2 .CircularProgressbar-trail {
  stroke: #e9faf3 !important;
}
.cardin3 .CircularProgressbar-trail {
  stroke: #ffdede !important;
}
.cardin4 .CircularProgressbar-trail {
  stroke: #dfffd6 !important;
}
.iconse svg path {
  stroke: white;
  stroke-width: 0.5px;
}
.current {
  width: 75%;
}
.MostO {
  width: 25%;
}
