.MainKiosk {
  display: flex;
  flex-direction: column;
}
.ksection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 2rem;
  overflow-y: scroll;
}
.Kcard {
  background-color: white;
  border-radius: 0.8rem;
  padding: 2rem;
  width: 14rem;
}
.ktop {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.kname {
  font-family: var(--Ffamily);
  font-size: 18px;
}
.kinfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  border-top: 1px solid #eeeeee;
}
.kinfo1 {
  font-size: 15px;
  margin: 1rem 0 0 0;
}
.kinfo2 {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}
.clonebutton {
  display: flex;
  color: white;
  font-size: 14px;
  font-weight: 500;
  margin-top: 1rem;
  justify-content: center;
  font-family: var(--Ffamily);
  background: var(--button);
  align-items: center;
  gap: 0.5rem;
  border: none;
  border-radius: 0.85rem;
  height: 2.8rem;
  width: 100%;
  padding: 0 1rem 0 1rem;
  cursor: pointer;
}
.clone svg path {
  stroke: white;
}
.clone {
  margin-top: 0.2rem;
}
