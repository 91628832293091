.Mainkitchen {
  display: flex;
  flex-direction: column;
}
.ordersinfo {
  display: flex;
  flex-direction: row-reverse;
}
.ordersTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  height: 69vh;
  margin-top: 2rem;
  padding-top: 1rem;
  gap: 2rem;
  border-radius: 0.5rem;
}
.Toggle-Order-2 {
  display: flex;
  margin-top: 1rem;
  gap: 8.2rem;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
}
.ordersection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  margin: 1rem;
  padding: 0;
}
.orderrows {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fbfbfb;
  border-radius: 0.5rem;
  height: 50vh;
  overflow-y: auto;
  width: 38vw;
}
.wdrow {
  width: 45vw !important;
}
.Heading1 {
  display: flex;
  background: #00c2ff;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0 1rem 0;
  border-radius: 9px 9px 0px 0px;
  position: sticky;
}
.Heading2 {
  display: flex;
  background: #ff8c00;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0 1rem 0;
  border-radius: 9px 9px 0px 0px;
}
.Heading3 {
  display: flex;
  background: #00e384;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0 1rem 0;
  border-radius: 9px 9px 0px 0px;
}
.Heading4 {
  display: flex;
  background: #ff6767;
  width: 100%;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0 1rem 0;
  border-radius: 9px 9px 0px 0px;
}
.row-text {
  font-family: var(--Ffamily);
  font-weight: 600;
  color: white;
}
.ordercal1 {
  background: white;
  border-radius: 1rem;
  margin: 0;
  padding: 0 0.2rem 0 0.2rem;
  font-family: var(--Ffamily);
  font-weight: 500;
  color: #00c2ff;
}
.ordercal2 {
  background: white;
  border-radius: 1rem;
  margin: 0;
  padding: 0 0.2rem 0 0.2rem;
  font-family: var(--Ffamily);
  font-weight: 500;
  color: #ff8c00;
}
.ordercal3 {
  background: white;
  border-radius: 1rem;
  margin: 0;
  padding: 0 0.2rem 0 0.2rem;
  font-family: var(--Ffamily);
  font-weight: 500;
  color: #00e384;
}
.ordercal4 {
  background: white;
  border-radius: 1rem;
  margin: 0;
  padding: 0 0.2rem 0 0.2rem;
  font-family: var(--Ffamily);
  font-weight: 500;
  color: #ff6767;
}
.ordersstring {
  width: 100%;
}
.ordercard {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  background: white;
  border-radius: 0.5rem;
  padding: 0.7rem;
  gap: 0.5rem;
}
.ordertitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ordernumber {
  font-family: var(--Ffamily);
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #4f4f4f;
}
.myicon {
  cursor: pointer;
}
.ordertype {
  font-family: var(--Ffamily);
  font-size: 13px;
  text-align: left;
  color: #4f4f4f;
}
.rate {
  font-family: var(--Ffamily);
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #4f4f4f;
}
.timing-table {
  display: flex;
  justify-content: space-between;
}
.timing {
  font-family: var(--Ffamily);
  font-size: 13px;
  text-align: left;
  color: #959595;
}
.tabling {
  font-family: var(--Ffamily);
  font-size: 13px;
  text-align: left;
  color: #4f4f4f;
}
.btns {
  display: flex;
  justify-content: space-between;
}
.cancel {
  font-family: var(--Ffamily);
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: white;
  border: none;
  background: #ff0000;
  border-radius: 0.2rem;
  padding: 0.4rem;
  cursor: pointer;
}
.start {
  font-family: var(--Ffamily);
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: white;
  border: none;
  background: #ff8c00;
  border-radius: 0.2rem;
  padding: 0.4rem;
  cursor: pointer;
}
.Maindsection {
  width: 100%;
  margin: 0;
  padding: 0%;
}
