.Modalbg {
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.ModalContainer {
  width: 250px;
  height: auto;
  max-height: 600px;
  border-radius: 12px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 25px;
  overflow-y: scroll;
}
.btn {
  background: var(--button);
  font-family: var(--Ffamily);
  font-weight: 600;
  border: none;
  border-radius: 1rem;
  margin: 0;
  width: 10rem;
  color: white;
  padding: 1rem;
  cursor: pointer;
}
.ct {
  justify-content: center !important;
  /* flex-direction: ; */
}
.dr {
  gap: 0.01rem !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.loadtlt {
  font-size: small;
  font-weight: 600;
  color: #f6951f;
}
#Cancel {
  background: #c4c4c4;
}
.ModalTitle {
  display: inline-block;
  text-align: center;
  margin-top: -27px;
  pointer-events: none;
}
.Modalbody {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.Modalbtn {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  gap: 2rem;
}
.closebtn {
  display: flex;
  justify-content: flex-end;
}
.closebtn button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.Modalbody input {
  font-family: var(--Ffamily);
  padding: 10px;
  color: #5e5e5e;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
}
.Modalbody input:focus-visible {
  outline: 0px !important;
}
.Modalbody select {
  font-family: var(--Ffamily);
  padding: 10px;
  color: #5e5e5e;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
}
.Modalbody label {
  font-family: var(--Ffamily);
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 0.5rem;
  cursor: pointer;
}
.Modalbody .toggling label {
  padding-bottom: 0;
  margin-right: 0.4rem;
}
.userTitle {
  font-size: 12px;
}

.fields {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.fields .css-1s2u09g-control {
  border: 1px solid #e5e5e5 !important;
  border-color: #e5e5e5 !important;
  box-sizing: border-box !important;
  transition: none !important;
  border-width: 1px !important;
  padding: 2px !important;
}
.fields .css-qc6sy-singleValue {
  font-size: 0.9rem !important;
}
.fields .css-14el2xx-placeholder {
  font-size: 0.9rem !important;
}
.Imgs {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #ffebd2;
  border-radius: 5px;
  border: 2px dashed #f6951f;
  width: 50%;
}
.w {
  width: 100%;
  height: 150px;
}
.dblsection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  height: 100%;
}
.dblsection2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 100%;
}
.snglsection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  justify-content: space-between;
}
.snglsection2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  justify-content: flex-start;
}
.mains {
  color: #f6951f;
  font-family: var(--Ffamily);
}
.tlt {
  font-size: 15px;
}
.Most {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.ttl {
  font-size: 13px;
  font-weight: 500;
}
.Attl {
  width: 44%;
}
.ptlt {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 56%;
  gap: 0.5rem;
}
.price {
  width: 60%;
}
.delete {
  cursor: pointer;
  padding: 7px;
  background: red;
  color: white;
  border: none;
  margin-top: -25px;
  opacity: 0;
}
.preview {
  display: flex;
  flex-direction: column;
  margin-left: -22px;
}
.image {
  max-width: 25px;
  max-height: 25px;
  background-color: white;
}
.add-img {
  margin-top: 7px;
}
.camera {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  padding-left: 10px;
}
.extra {
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-sizing: border-box;
}
.extra-input {
  border-width: 0px !important;
  /* border-radius: 0px !important;
    box-sizing: border-box !important; */
}
.border {
  width: auto;
  border-left: 1px solid #e5e5e5 !important;
  border-radius: 0px !important;
  box-sizing: border-box !important;
  background: none;
}
.morebtn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.add-more {
  font-family: var(--Ffamily);
  font-size: 15px;
  font-weight: 500;
  color: #f6951f;
  background: none;
  border: none;
  cursor: pointer;
}
.del-extra {
  margin-right: 5px;
  background: none;
  border: none;
  cursor: pointer;
}

/* dropdowns */

.selectdiv {
  position: relative;
  float: left;
  width: 100%;
}

select::-ms-expand {
  display: none;
}

.selectdiv:after {
  content: "<>";
  font: 17px var(--Ffamily);
  color: rgb(109, 109, 109);
  gap: 1rem;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 12px;
  top: 37px;
  padding: 0 0 2px;
  border-bottom: 1px solid #999;
  position: absolute;
  pointer-events: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  gap: 2rem;
  height: 45px;
  float: right;
  margin: 5px 0px;
  padding: 0px 24px;
  font-size: 15px;
  line-height: 1.75;
  color: rgb(103, 103, 103);
  background-color: #ffffff;
  background-image: none;
  border-left: 1px solid #e5e5e5;
  -ms-word-break: normal;
  word-break: normal;
}
option:checked {
  color: #ffffff;
  background-color: #ff8c00;
}
.iimg {
  width: 130px;
  height: 130px;
}
.imgdel {
  margin-top: -13%;
  z-index: 100;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
}
.center {
  display: flex;
  flex-direction: column;
}
.fs {
  align-items: flex-start;
}
.error {
  font-family: var(--Ffamily);
  font-size: 12px;
  text-align: center;
  color: red;
}
.btm {
  margin-bottom: 1rem;
}
