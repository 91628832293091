.Contain {
    display: flex;
    flex-direction: column;
}
.fieldss {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    /* gap: .1rem; */
    
}
.fieldss input {
    font-family: var(--Ffamily);
    padding: 10px;
    color: #5e5e5e;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    outline: none;
}
.fieldss label {
    font-family: var(--Ffamily);
    font-size: 13px;
    font-weight: 500;
    padding: .5rem 0 .5rem 0;
    cursor: pointer;
}
.fieldss .css-1s2u09g-control {
    border: 1px solid #e5e5e5 !important;
    border-color: #e5e5e5 !important;
    box-sizing: border-box !important;
    transition: none !important;
    border-width: 1px !important;
    padding: 2px !important;
}

.dblsections{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.Savebtn {
    display: flex;
    background: var(--button);
    align-items: center;
    gap: .5rem;
    border: none;
    border-radius: .85rem;
    height: 2.8rem;
    padding: 0 1rem 0 1rem;
    margin-bottom: 2rem;
    cursor: pointer;
}
.setbtn {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1rem;
}
.colorpicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 200px;
    height: 2rem;
}
.colorpicker input {
    padding: 0px 5px 0 8px;
    width: 50%;
    border: 0px;
    outline: none;
}
input[type="color" i] {
    border-radius: 4px;
    width: 40px;
    background-color: white;
    border-color: white;
}
.Imagepicker {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1rem;
}
.Imagebtn {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.imgee {
    border: 1px dashed #000000;
    padding: 10px;
}
.Imagebtn button {
    font-family: var(--Ffamily);
    font-size: 13px;
    font-weight: 500;
    background-color: #FF8C00;
    border: none;
    color: white;
    border-radius: .5rem;
    height: 2.05rem;
    padding: 0 1rem 0 1rem;
    cursor: pointer;
}
.custominp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border: 1px solid #D8D8D8;
    width: 100%;
    height: 40px;
    border-radius: 5px;

}
.c1 {
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    gap: 1rem;
}
.c2 {
    display: flex;
    flex-direction: row;
    padding-right: 1rem;
    gap: 1rem;
}
.langico {
    border-radius: 100%;
}
.langico{
    align-items: center;
    width: 20px;
    height: 20px;
    margin: 3px;
}
.ED {
   display: flex;
   justify-content: center;
   gap: .5rem;
   cursor: pointer;
   padding-left: 1rem;
   border-left: 1px solid #D8D8D8; 
}
.str {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}
.statuslng {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
    box-sizing: border-box;
    width: 74px;
    height: 23px;
    background: #DEFFF1;
    border: 1px solid #00E384;
    border-radius: 11px;
    color: #00E384;
    font-weight: 400;
}
.bottim {
    margin-bottom: 30px;
}
.fields input {
    font-family: var(--Ffamily);
    padding: 10px;
    color: #5e5e5e;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
}
.fields input:focus-visible {
    outline: 0px !important;
}
label {
    font-family: var(--Ffamily);
    font-size: 12px;
    font-weight: 500;
    padding-bottom: .5rem;
    cursor: pointer;
}
.toggling label {
    padding-bottom: 0;
    margin-right: .4rem;
}
.dblsections {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    height: 100%;
}