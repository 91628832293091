.MainUsers {
  display: flex;
  flex-direction: column;
}
.Top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filters {
  display: flex;
  align-items: center;
  padding-top: 2rem;
  gap: 1rem;
}
.buttonic {
  display: flex;
  align-items: center;
  padding-top: 2rem;
  gap: 1rem;
}
.titlefilter {
  font-size: 22px;
  font-weight: 500;
}
.custom-select {
  display: flex;
  height: 2.5rem;
  width: 12rem;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  z-index: 2;
}
select {
  font-family: var(--Ffamily);
  font-weight: 500;
  background-color: rgb(255, 255, 255) !important;
  color: #ff8c00;
  border: none;
  border-radius: 0%;
  height: 2.5rem;
  width: 10rem;
  margin: 0;
  outline: white;
}
select option {
  color: black;
  border: none;
  display: flex;
  outline: white;
}
select option::hover {
  color: rgb(255, 0, 0);
}
.css-1s2u09g-control {
  border-width: 0px !important;
  width: 100% !important;
  cursor: pointer !important;
}
.css-6j8wv5-Input {
  display: flex !important;
  cursor: pointer !important;
}
.css-tlfecz-indicatorContainer svg path {
  fill: #ff8c00 !important;
}
.custom-search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.custom-search input {
  font-size: 14px;
  font-family: var(--Ffamily);
  height: 2.4rem;
  width: 21rem;
  justify-content: center;
  border-radius: 0.3rem;
  cursor: pointer;
  outline: 0px;
  padding-left: 1rem;
  padding-right: 3.5rem;
}
.Iconic {
  margin-top: 0.5rem;
  position: relative;
  margin-left: -2.5rem;
  pointer-events: none;
}
.userlist {
  margin-top: 1rem;
}
.toggling {
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  transform: scale(1.5);
}
