.MainItems {
  display: flex;
  flex-direction: column;
}
.ITop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 2rem;
  gap: 2rem;
  align-content: flex-start;
}
.Toggle-table {
  display: flex;
  gap: 2rem;
  background-color: white;
  border-radius: 0.5rem;
}
.itemlist {
  margin-top: 1rem;
}
